const theme = {
  // Brand
  oj: "#FF8A00",
  oceanBlue: "#00031E",
  // Shade
  shade: {
    0: "#111112",
    10: "#3C3A3E",
    20: "#666369",
    30: "#8D8790",
    40: "#AEA6B0",
    50: "#C8BFC9",
    60: "#DCD3DC",
    70: "#EAE2EA",
    80: "#F4EFF4",
    90: "#FBF8FB",
    100: "#FFFFFF",
  },
  // Max Widths & Breakpoint
  maxWidth: "1200px",
  maxWidthImage: "840px",
  maxWidthText: "720px",
  gutter: "24px",
  breakpoints: {
    xs: "400px",
    sm: "600px",
    md: "900px",
    lg: "1200px",
    xl: "1600px",
  },
  // Other
  radius: {
    default: "4px",
  },
  opacity: {
    default: 0.4,
  },
  technical: {
    defaultColor: "#EEEEEE",
  },
}

export default theme
